import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Home';


function App() {
    return (
        <>
       <Home />
        </>
    );
}


export default App;


